// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import store from './store'
import App from './App'
import router from './router'
import './config/index'
import 'babel-polyfill'

import * as filters from './filters'

import './assets/style/index.css'

// import VueLazyLoad from 'vue-lazyload'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import BaiduMap from 'vue-baidu-map'

// 导入
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(BaiduMap, {
  ak: 'nVQ3ub7penqoC2Yf2qQm8wogcWk5hQI1'
})

/* Vue.use(VueLazyLoad, {
  error: './static/error.jpg',
  loading: './static/loading.gif'
}) */
Vue.use(ElementUI)

// Vue.prototype.$url = 'http://jhsapi.hengyueol.com/images/'
Vue.prototype.$url = ''

// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach((to, from, next) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0
  NProgress.done()
})

Vue.config.productionTip = false

/* eslint-disable no-new */
export default new Vue({
  el: '#app',
  router,
  store,
  components: {
    App
  },
  template: '<App/>'
})
