import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './action'
import getters from './getters'

Vue.use(Vuex)

const state = {
  navNum: '0',
  cartNum: '0',
  loginStatus: false, // 登录状态
  user: {
    id: '',
    name: ''
  } // 用户信息
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
