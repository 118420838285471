export function sub30 (val) {
  if (val.length > 28) {
    return val.substring(0, 28) + '...'
  } else {
    return val
  }
}

export function getnum (val) {
  return Math.round(val * 100) / 100
}

export function payStatusId (val) {
  if (val === 1) {
    return '余额支付'
  } else if (val === 2) {
    return '支付宝支付'
  } else if (val === 3) {
    return '微信支付'
  }
}
export function gameType (val) {
  if (val === 1) {
    return '角色扮演'
  } else if (val === 2) {
    return '应用游戏'
  } else if (val === 3) {
    return '策略游戏'
  }
}

// 订单状态
export function orderStatus (val) {
  if (val === 1) {
    return '待支付'
  } else if (val === 2) {
    return '已支付'
  } else if (val === 3) {
    return '待收货'
  } else if (val === 4) {
    return '待收货'
  } else if (val === 5) {
    return '已完成'
  } else if (val === 6) {
    return '已取消'
  } else {
    return '未支付'
  }
}

// 截取时间
export function subTime (val) {
  return val.substring(0, 10)
}

// 截取标题
export function subTitle (val) {
  if (val.length > 22) {
    return val.substring(0, 22) + '...'
  }
  return val
}

// 补0
export function returnFloat (value) {
  let svalue = Math.round(parseFloat(value) * 100) / 100
  var xsd = svalue.toString().split('.')
  if (xsd.length === 1) {
    svalue = svalue.toString() + '.00'
    return svalue
  }
  if (xsd.length > 1) {
    if (xsd[1].length < 2) {
      svalue = svalue.toString() + '0'
    }
    return svalue
  }
}
