import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: resolve => require(['@/pages/index'], resolve)
    },
    {
      path: '/index',
      name: 'index',
      component: resolve => require(['@/pages/index'], resolve)
    },
    // 列表页
    {
      path: '/page',
      name: 'page',
      component: resolve => require(['@/pages/page'], resolve)
    },
    // 下载中心
    {
      path: '/download',
      name: 'download',
      component: resolve => require(['@/pages/download'], resolve)
    },
    // 发布需求
    {
      path: '/userPost',
      name: 'userPost',
      component: resolve => require(['@/pages/userPost'], resolve)
    },
    // 套餐详情
    {
      path: '/show',
      name: 'show',
      component: resolve => require(['@/pages/show'], resolve)
    },
    // 查询大厅
    {
      path: '/search',
      name: 'search',
      component: resolve => require(['@/pages/search'], resolve)
    },
    // 政策列表
    {
      path: '/listzc',
      name: 'listzc',
      component: resolve => require(['@/pages/listzc'], resolve)
    },
    // 政策详情
    {
      path: '/showzc',
      name: 'showzc',
      component: resolve => require(['@/pages/showzc'], resolve)
    },
    // 兼职大厅
    {
      path: '/listjz',
      name: 'listjz',
      component: resolve => require(['@/pages/listjz'], resolve)
    },
    // 供需大厅
    {
      path: '/listgx',
      name: 'listgx',
      component: resolve => require(['@/pages/listgx'], resolve)
    },
    // 抢单大厅
    {
      path: '/listqd',
      name: 'listqd',
      component: resolve => require(['@/pages/listqd'], resolve)
    },
    // 补贴大厅
    {
      path: '/listbq',
      name: 'listbq',
      component: resolve => require(['@/pages/listbq'], resolve)
    },
    // 登录
    {
      path: '/login',
      name: 'login',
      component: resolve => require(['@/pages/login'], resolve)
    },
    // 注册
    {
      path: '/register',
      name: 'register',
      component: resolve => require(['@/pages/register'], resolve)
    },
    // 我的购物车
    {
      path: '/cart',
      name: 'cart',
      component: resolve => require(['@/pages/cart'], resolve)
    },
    // 我的收货地址
    {
      path: '/address',
      name: 'address',
      component: resolve => require(['@/pages/address'], resolve)
    },
    // 我的订单
    {
      path: '/order',
      name: 'order',
      component: resolve => require(['@/pages/order'], resolve)
    },
    // 下订单
    {
      path: '/orderPut',
      name: 'orderPut',
      component: resolve => require(['@/pages/orderPut'], resolve)
    },
    // 添加修改收货地址
    {
      path: '/addressPut',
      name: 'addressPut',
      component: resolve => require(['@/pages/addressPut'], resolve)
    },
    // 支付订单
    {
      path: '/orderPay',
      name: 'orderPay',
      component: resolve => require(['@/pages/orderPay'], resolve)
    },
    // 合作申请
    {
      path: '/hezuo',
      name: 'hezuo',
      component: resolve => require(['@/pages/hezuo'], resolve)
    },
    // 合作申请
    {
      path: '/1',
      name: '1',
      component: resolve => require(['@/pages/1'], resolve)
    }
  ]
})
