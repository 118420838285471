<template>
  <div id="app">
    <v-header></v-header>
    <router-view />
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
export default {
  name: 'App',
  components: { vHeader, vFooter },
  mounted () {},
  methods: {
  }
}
</script>
<style type="text/scss" lang="scss">
#app{
  background: #f8f8f8;
}
.w1200{
  width: 1200px;margin: auto;
}
.sTitle{
  font-size: 18px;border-bottom: 2px solid #ddd;line-height: 2;padding-left: 10px;color: #666;
  span{float: right;}
}
.none{text-align: center;font-size: 16px;color: #999;padding-bottom: 100px;
  .icon{width: 100px;height: 100px;display: inline-block;margin: 60px auto 0 auto;background: #ddd;opacity: 0.3;
    &.ncart{background: url('/static/n1.png') center no-repeat;background-size: 60%;}
    &.nshop{background: url('/static/n4.png') center no-repeat;background-size: 60%;}
  }
  .t1{
    line-height: 1;margin: 0;color: #ccc;
  }
  .t2{
    span{margin-top: 100px;display: inline-block;background: #c2090f;color: #fff;padding: 8px 24px;border-radius: 4px;cursor: pointer;;}
  }
}
//添加地址
.addSpan{text-align: center;padding: 20px;
  span{
    display: inline-block;background: #c2090f;height: 36px;line-height: 36px;border-radius: 4px;cursor: pointer;color: #fff;font-size: 16px;padding-right: 16px;margin: auto;
    i{width: 36px;height: 36px;display: inline-block;background: url('/static/jia2.png') center no-repeat;background-size: 50%;vertical-align: middle;}
  }
}

.itemXin{overflow: hidden;margin-top: 20px;
    .title{width: 100%;height: 50px;line-height: 46px;background: url('/static/titlebg.png') center no-repeat;background-size: 100% 100%;font-size: 20px;color: #ffef65;text-indent: 20px;overflow: hidden;
      position: relative;font-size: 22px;text-shadow: #4b76ce 3px 3px 3px;margin-bottom: 10px;;
      span{float: right;padding-right: 20px;cursor: pointer;text-shadow:none;font-size: 16px;color: #1ba0f8;line-height: 50px;background: url('/static/spanbg.png') center no-repeat;background-size: 100% 100%;}
    }
    ul{
      padding: 20px;min-height: 600px;
      li{width: 250px;height: 440px;;float: left;text-align: center;box-sizing: border-box;margin-right: 23px;margin-bottom: 20px;position: relative;background: #fff;
        &:nth-child(4n){
          margin-right: 0;
        }
        .imgBox{width: 100%;height: 250px;overflow: hidden;
            img{width: 100%;transition-duration:0.5s;cursor: pointer;
                &:hover{
                    transform:scale(1.12,1.12);
                }
            }
        }
        .p0{
            position: relative;
            span{background: #01a5c0;color: #fff;display: inline-block;padding: 6px 20px;border-radius: 6px;font-size: 12px;position: absolute;left: 50%;margin-left: 50px;margin-top: -36px;width: 70px;margin-left: -55px;}
        }
        .p1{font-size: 14px;color: #01a5c0;text-align: left;line-height: 40px;padding: 0 20px;}
        .p2{font-size: 14px;color: #000;text-align: left;padding-left: 4px;line-height: 20px;padding: 0 20px;
            overflow: hidden;
text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号
display: -webkit-box;
-webkit-line-clamp: 2; // 设置两行文字溢出
-webkit-box-orient: vertical;
overflow:hidden;
text-overflow:ellipsis;}
        .p3{font-size: 14px;color: #666;text-align: left;padding-left: 4px;line-height: 22px;padding: 4px 20px;overflow: hidden;margin-top: 10px;
text-overflow: ellipsis;overflow:hidden;display: -webkit-box;
-webkit-line-clamp: 1; // 设置两行文字溢出
-webkit-box-orient: vertical;
text-overflow:ellipsis;}
        .p4{font-size: 14px;color: #0dcaf0;text-align: left;padding-left: 4px;line-height: 22px;padding: 4px 20px;cursor: pointer;margin-top: 10px;}
      }
    }
    
  }
</style>
