import axios from './client'

// 广告列表
export const _bannerList = (type) => {
    return axios('get', '/banner?type=' + type)
}

// 用户提交需求
export const _userNeed = data => {
    return axios('post', '/userNeed', data)
}

// 用户提交信息
export const _userPost = data => {
    return axios('post', '/userPost', data)
}

// 导航信息
export const _navList = () => {
    return axios('get', '/nav')
}

// 导航详情
export const _navDesc = id => {
    return axios('get', '/nav/' + id)
}

// 1抢单列表，2需求列表，3兼职列表
export const _adminQdList = data => {
    let page = data.page || 1
    let type = data.type
    return axios('get', '/adminQd?page=' + page + '&type=' + type)
}

// 补贴列表
export const _adminBqList = data => {
    let page = data || 1
    return axios('get', '/adminBq?page=' + page)
}

// 1政策列表,2全国新闻
export const _adminZcList = data => {
    let page = data.page || 1
    let type = data.type || 1
    return axios('get', '/adminZc?page=' + page + '&type=' + type)
}

// 1政策列表,2全国新闻详情
export const _adminZcDesc = id => {
    return axios('get', '/adminZc/' + id)
}

// 访问日志
export const _postLog = data => {
    return axios('post', 'http://www.lylcc.club/api/log', data)
}

// 细节信息
export const _titleList = () => {
    return axios('get', '/title')
}

// 搜索导航信息
export const _search = data => {
    return axios('post', '/search', data)
}

// 分类标题
export const _downloadTitle = data => {
    return axios('get', '/download/title', data)
}

// 分类内容
export const _download = data => {
    return axios('get', '/download/item', data)
}

// 展示案例
export const _showAnli = data => {
    return axios('get', '/show/anli', data)
}

// 展示可办理
export const _showBanli = data => {
    return axios('get', '/show/banli', data)
}

// 首页-二级导航
export const _navSec = data => {
    return axios('get', '/fuwu/navsec', data)
}

// 首页-服务
export const _fuwu = data => {
    return axios('get', '/fuwu/fuwu', data)
}

// 首页-发展历程
export const _fazhan = data => {
    return axios('get', '/fuwu/fazhan?type=' + data)
}

// 获取城市
export const _log = data => {
    return axios('post', 'http://www.log.zchlink.com/api/city', data)
}

// 商品列表
export const _goods = data => {
        return axios('get', '/goods?type=' + data)
    }
    // 商品详情
export const _goodsDesc = id => {
    return axios('get', '/goods?id=' + id)
}

// 首页商品列表
export const _indexs = () => {
    return axios('get', '/goods/index')
}

// 登录
export const _logins = data => {
        return axios('post', '/login', data)
    }
    // 注册
export const _register = data => {
    return axios('post', '/register', data)
}

// 购物车数量
export const _getCartNumber = () => {
    return axios('get', '/user/cart/number')
}

// 加入购物车
export const _addCart = data => {
    return axios('post', '/user/cart', data)
}

// 修改购物车
export const _putCart = (id, data) => {
    return axios('put', '/user/cart/' + id, data)
}

// 删除购物车
export const _delCart = id => {
    return axios('delete', '/user/cart/' + id)
}

// 我的购物车
export const _getCart = () => {
    return axios('get', '/user/cart')
}

// 检查购物车商品是否缺货
export const _checkCart = (data) => {
    return axios('post', '/check', data)
}

// 我的收货地址
export const _getAddress = () => {
    return axios('get', '/user/address')
}

// 添加收货地址
export const _postAddress = data => {
    return axios('post', '/user/address', data)
}

// 修改收货地址
export const _putAddress = (id, data) => {
    return axios('put', '/user/address/' + id, data)
}

// 删除收货地址
export const _delAddress = id => {
        return axios('delete', '/user/address/' + id)
    }
    // 收货地址详情
export const _getAddressOnce = id => {
    return axios('get', '/user/address/' + id)
}

// 用户下订单
export const _postOrder = data => {
        return axios('post', '/user/order', data)
    }
    // 用户订单列表
export const _getOrder = (page) => {
        return axios('get', '/user/order?page=' + page)
    }
    // 用户订单列表
export const _getOrderDesc = (id) => {
        return axios('get', '/user/order/' + id)
    }
    // 生成支付
export const _getPay = (id) => {
        return axios('get', '/user/order/pay/' + id)
    }
    // 公司信息
export const _getAbout = () => {
    return axios('get', '/about/1')
}


// 发布信息
export const _workList = data => {
    return axios('get', '/work?user=' + localStorage.getItem('userName'), data)
}
export const _workAdd = data => {
    return axios('post', '/work', data)
}