// 网站链接
export const linkArr = [{
  title: '国家部委网站',
  link: [{
    title: '商务部',
    url: 'http://www.mofcom.gov.cn/'
  },
  {
    title: '财政部',
    url: 'http://www.mof.gov.cn/'
  },
  {
    title: '司法部',
    url: 'http://www.moj.gov.cn/'
  },
  {
    title: '民政部',
    url: 'http://www.mca.gov.cn/'
  },
  {
    title: '教育部',
    url: 'http://www.moe.gov.cn/'
  },
  {
    title: '国防部',
    url: 'http://www.mod.gov.cn/'
  },
  {
    title: '国家税务总局',
    url: 'http://www.chinatax.gov.cn/'
  },
  {
    title: '海关总署',
    url: 'http://www.customs.gov.cn/'
  },
  {
    title: '水利部',
    url: 'http://www.mwr.gov.cn/'
  },
  {
    title: '农业农村部',
    url: 'http://www.moa.gov.cn/'
  },
  {
    title: '交通运输部',
    url: 'http://www.mot.gov.cn/'
  },
  {
    title: '文化和旅游部',
    url: 'http://www.mct.gov.cn/'
  },
  {
    title: '工业和信息化部',
    url: 'http://www.miit.gov.cn/'
  },
  {
    title: '国家发展和改革委员会',
    url: 'http://www.ndrc.gov.cn/'
  },
  {
    title: '国务院办公厅',
    url: 'http://www.gov.cn/'
  },
  {
    title: '外交部',
    url: 'https://www.fmprc.gov.cn/web/'
  },
  {
    title: '科学技术部',
    url: 'http://www.most.gov.cn/'
  }
  ]
},
{
  title: '各省市政府网站',
  link: [{
    title: '北京',
    url: 'http://www.beijing.gov.cn/'
  },
  {
    title: '天津',
    url: 'http://www.tj.gov.cn/'
  },
  {
    title: '河北',
    url: 'http://www.hebei.gov.cn/'
  },
  {
    title: '山西',
    url: 'http://www.shanxi.gov.cn'
  },
  {
    title: '内蒙古',
    url: 'http://www.nmg.gov.cn/'
  },
  {
    title: '辽宁',
    url: 'http://www.ln.gov.cn'
  },
  {
    title: '吉林',
    url: 'http://www.jl.gov.cn'
  },
  {
    title: '黑龙江',
    url: 'http://www.hlj.gov.cn'
  },
  {
    title: '上海',
    url: 'http://www.shanghai.gov.cn/'
  },
  {
    title: '江苏',
    url: 'http://www.jiangsu.gov.cn/'
  },
  {
    title: '浙江',
    url: 'http://www.zhejiang.gov.cn'
  },
  {
    title: '安徽',
    url: 'http://www.ah.gov.cn/'
  },
  {
    title: '福建',
    url: 'http://www.fujian.gov.cn/'
  },
  {
    title: '江西',
    url: 'http://www.jiangxi.gov.cn'
  },
  {
    title: '山东',
    url: 'http://www.shandong.gov.cn/'
  },
  {
    title: '河南',
    url: 'http://www.henan.gov.cn/'
  },
  {
    title: '湖北',
    url: 'http://www.hubei.gov.cn/'
  },
  {
    title: '湖南',
    url: 'http://www.hunan.gov.cn/'
  },
  {
    title: '广东',
    url: 'http://www.gd.gov.cn/'
  },
  {
    title: '广西',
    url: 'http://www.gxzf.gov.cn/'
  },
  {
    title: '海南',
    url: 'http://www.hainan.gov.cn/'
  },
  {
    title: '重庆',
    url: 'http://www.cq.gov.cn/'
  },
  {
    title: '四川',
    url: 'http://www.sc.gov.cn'
  },
  {
    title: '贵州',
    url: 'http://www.guizhou.gov.cn'
  },
  {
    title: '云南',
    url: 'http://www.yn.gov.cn/'
  },
  {
    title: '西藏',
    url: 'http://www.xizang.gov.cn/'
  },
  {
    title: '陕西',
    url: 'http://www.shaanxi.gov.cn/'
  },
  {
    title: '甘肃',
    url: 'http://www.gansu.gov.cn/'
  },
  {
    title: '宁夏',
    url: 'http://www.nx.gov.cn/'
  },
  {
    title: '青海',
    url: 'http://www.qh.gov.cn/'
  },
  {
    title: '新疆',
    url: 'http://www.xinjiang.gov.cn/'
  },
  {
    title: '香港',
    url: 'http://www.gov.hk/'
  },
  {
    title: '澳门',
    url: 'https://www.gov.mo'
  }
  ]
},
{
  title: '政府相关网站',
  link: [{
    title: '广州市文化广电旅游局',
    url: 'http://wglj.gz.gov.cn/'
  },
  {
    title: '广东省发展和改革委员会',
    url: 'http://www.gddrc.gov.cn/'
  },
  {
    title: '广东省工业和信息化厅',
    url: 'http://gdii.gd.gov.cn/'
  },
  {
    title: '广东省教育厅',
    url: 'http://edu.gd.gov.cn/'
  },
  {
    title: '广东省科学技术厅',
    url: 'http://gdstc.gd.gov.cn/'
  },
  {
    title: '广东省公安厅',
    url: 'http://gdga.gd.gov.cn/'
  },
  {
    title: '广东省水利厅',
    url: 'http://slt.gd.gov.cn/'
  },
  {
    title: '广东省商务厅',
    url: 'http://com.gd.gov.cn/'
  },
  {
    title: '广东省人民政府外事办公室',
    url: 'http://www.gdfao.gd.gov.cn/'
  },
  {
    title: '中共广东省纪律检查委员会',
    url: 'http://www.gdjct.gd.gov.cn/'
  },
  {
    title: '广东省民政厅',
    url: 'http://smzt.gd.gov.cn/'
  },
  {
    title: '广东省司法厅',
    url: 'http://sft.gd.gov.cn/'
  },
  {
    title: '广东省自然资源厅',
    url: 'http://nr.gd.gov.cn/'
  },
  {
    title: '广东省审计厅',
    url: 'http://www.gdaudit.gov.cn/'
  },
  {
    title: '广东省税务局',
    url: 'http://www.gd-n-tax.gov.cn/gdsw/index.shtml'
  },
  {
    title: '广东省广播电视局',
    url: 'http://gbdsj.gd.gov.cn/'
  },
  {
    title: '广东省人民政府办公厅',
    url: 'http://www.gd.gov.cn/'
  }
  ]
},
{
  title: '市政府相关网站',
  link: [{
    title: '广州市农业农村局',
    url: 'http://www.gzagri.gov.cn/'
  },
  {
    title: '广州市财政局',
    url: 'http://www.gzfinance.gov.cn/'
  },
  {
    title: '广州市司法局',
    url: 'http://www.gzsfj.gov.cn/'
  },
  {
    title: '广州市民政局',
    url: 'http://www.gzmz.gov.cn/'
  },
  {
    title: '广州市纪律检查委员会',
    url: 'http://www.gzjjjc.gov.cn/'
  },
  {
    title: '广州市公安局',
    url: 'http://www.gzjd.gov.cn/'
  },
  {
    title: '广州市科学技术局',
    url: 'http://www.gzsi.gov.cn/'
  },
  {
    title: '广州市教育局',
    url: 'http://www.gzedu.gov.cn/'
  },
  {
    title: '广州市商务局',
    url: 'http://www.gzcoc.gov.cn/'
  },
  {
    title: '广州市发展和改革委员会',
    url: 'http://www.gzplan.gov.cn/'
  },
  {
    title: '广州市人民政府',
    url: 'http://www.gz.gov.cn/'
  },
  {
    title: '广州市文物局',
    url: 'http://wglj.gz.gov.cn/'
  }
  ]
},
{
  title: '各区政府相关网站',
  link: [{
    title: '广州市荔湾区人民政府',
    url: 'http://www.lw.gov.cn/'
  },
  {
    title: '广州市荔湾区发展改革局',
    url: ''
  },
  {
    title: '广州市荔湾区科工商信局',
    url: ''
  },
  {
    title: '广州市荔湾区教育局',
    url: ''
  },
  {
    title: '广州市荔湾区财政局',
    url: ''
  },
  {
    title: '广州市荔湾区人力社保局',
    url: ''
  },
  {
    title: '广州市荔湾区文广旅体局',
    url: ''
  },
  {
    title: '广州市荔湾区卫生健康局',
    url: ''
  },
  {
    title: '广州市荔湾区审计局',
    url: ''
  },
  {
    title: '广州市荔湾区市场监督管理局',
    url: ''
  },
  {
    title: '广州海珠区人民政府办公室',
    url: 'http://www.haizhu.gov.cn/'
  },
  {
    title: '广州海珠区发改局',
    url: ''
  },
  {
    title: '广州海珠区教育局',
    url: ''
  },
  {
    title: '广州海珠区科工商信局',
    url: ''
  },
  {
    title: '广州海珠区财政局',
    url: ''
  },
  {
    title: '广州海珠区人社局',
    url: ''
  },
  {
    title: '广州海珠区文化广电旅游体育局',
    url: ''
  },
  {
    title: '广州海珠区卫生健康局',
    url: ''
  },
  {
    title: '广州海珠区区市场监督管理局（区知识产权局）',
    url: ''
  },
  {
    title: '广州市越秀区人民政府',
    url: 'http://www.yuexiu.gov.cn'
  },
  {
    title: '广州市越秀区发展和改革局',
    url: ''
  },
  {
    title: '广州市越秀区教育局',
    url: ''
  },
  {
    title: '广州市越秀区科技工业和信息化局',
    url: ''
  },
  {
    title: '广州市越秀区财政局',
    url: ''
  },
  {
    title: '广州市越秀区人力资源和社会保障局',
    url: ''
  },
  {
    title: '广州市越秀区商务局',
    url: ''
  },
  {
    title: '广州市越秀区文化广电旅游体育局',
    url: ''
  },
  {
    title: '广州市越秀区卫生健康局',
    url: ''
  },
  {
    title: '广州市越秀区市场监督管理局',
    url: ''
  },
  {
    title: '广州市越秀区金融工作局',
    url: ''
  }
  ]
}
]

// 搜索
export const searchArr = [{
  title: '学校名单查询'
},
{
  title: '学生（考生）名单查询'
}, {
  title: '研究生'
}, {
  title: '普通高考'
}, {
  title: '对口/专升本'
}, {
  title: '成人高考'
}, {
  title: '自学考试'
}, {
  title: '中招中专'
}, {
  title: '学籍查询'
}, {
  title: '学生和家长'
}, {
  title: '证书校验'
}, {
  title: '其他查询'
}
]

// 下载列表
export const downloadArr = [{
  id: 1,
  title: '有限责任公司',
  son: [{
    title: '各类公司经营范围大全?',
    url: '',
    number: 5641
  }, {
    title: '注册公司准备材料?',
    url: '',
    number: 11364
  }, {
    title: '公司注册资金怎么填?',
    url: '',
    number: 24060
  }, {
    title: '如何查询公司信息?',
    url: '',
    number: 13405
  }, {
    title: '公司一定要买社保吗?',
    url: '',
    number: 11245
  }, {
    title: '公司登记（备案）申请书（填写示范版）',
    url: '',
    number: 2405
  }, {
    title: '公司章程（多人股东）（空白版）',
    url: '',
    number: 34570
  }, {
    title: '公司章程（一人有限公司不设董事会不设监事会）（空白版）',
    url: '',
    number: 1025
  }, {
    title: '注册公司流程?',
    url: '',
    number: 64250
  }, {
    title: '注册公司如何核名?',
    url: '',
    number: 1245
  }, {
    title: '公司成立后怎么报税?',
    url: '',
    number: 13884
  }, {
    title: '公司不报税会怎么样?',
    url: '',
    number: 1204
  }, {
    title: '公司登记（备案）申请书 （空白版）',
    url: '',
    number: 8546
  }, {
    title: '公司设立登记提交材料目录',
    url: '',
    number: 9122
  }, {
    title: '公司章程（多人股东）（填写示范版）',
    url: '',
    number: 12843
  }, {
    title: '公司章程（一人有限公司不设董事会不设监事会）（填写示范版）',
    url: '',
    number: 10459
  }]
}, {
  id: 2,
  title: '合伙企业',
  son: [{
    title: '出资权属证明（空白版）',
    url: '',
    number: 1086
  }, {
    title: '合伙企业公司（备案）申请书（空白版）',
    url: '',
    number: 2145
  }, {
    title: '合伙企业设立登记提交材料目录',
    url: '',
    number: 2413
  }, {
    title: '合伙协议（填写示例版）',
    url: '',
    number: 1240
  }, {
    title: '企业名称预先核准申请书（填写示范版）',
    url: '',
    number: 11401
  }, {
    title: '上海市私钥版电子营业执照申请书（填写示范版）',
    url: '',
    number: 5142
  }, {
    title: '委托书（填写示例版）',
    url: '',
    number: 968
  }, {
    title: '执行合伙事务的合伙人委托书（填写示例版）',
    url: '',
    number: 10241
  }, {
    title: '出资权属证明（填写示例版）',
    url: '',
    number: 12014
  }, {
    title: '合伙企业公司（备案）申请书（填写示例版）',
    url: '',
    number: 869
  }, {
    title: '合伙协议（空白版）',
    url: '',
    number: 2410
  }, {
    title: '企业名称预先核准申请书（空白版）',
    url: '',
    number: 1765
  }, {
    title: '上海市私钥版电子营业执照申请书（空白版）',
    url: '',
    number: 965
  }, {
    title: '执行合伙事务的合伙人委托书（空白版）',
    url: '',
    number: 12450
  }, {
    title: '指定代表或共同委托代理人授权委托书（空白版）',
    url: '',
    number: 10233
  }]
}, {
  id: 3,
  title: '分公司',
  son: [{
    title: '分公司设立登记表（空白版）',
    url: '',
    number: 20148
  }, {
    title: '分公司设立登记提交材料目录',
    url: '',
    number: 1224
  }, {
    title: '任命书（空白版）',
    url: '',
    number: 10114
  }, {
    title: '指定代表或共同委托代理人授权委托书（空白版）',
    url: '',
    number: 12368
  }, {
    title: '分公司设立登记表（填写示范版）',
    url: '',
    number: 2451
  }, {
    title: '告知承诺书',
    url: '',
    number: 1346
  }, {
    title: '任命书（填写示范版）',
    url: '',
    number: 1245
  }]
}, {
  id: 4,
  title: '个人独资企业',
  son: [{
    title: '个人独资企业备案提交材料目录',
    url: '',
    number: 2109
  }, {
    title: '个人独资企业登记（备案）申请书（填写示范版）',
    url: '',
    number: 10221
  }, {
    title: '认缴出资证明（填写示范版）',
    url: '',
    number: 2688
  }, {
    title: '指定代表或共同委托代理人授权委托书（填写示例版）',
    url: '',
    number: 1024
  }, {
    title: '个人独资企业登记（备案）申请书（空白版）',
    url: '',
    number: 3014
  }, {
    title: '认缴出资证明（空白版）',
    url: '',
    number: 21048
  }, {
    title: '指定代表或共同委托代理人授权委托书（空白版）',
    url: '',
    number: 3014
  }]
}, {
  id: 5,
  title: '劳动人事合同',
  son: [{
    title: '劳动合同（正式员工&正常交社保）',
    url: '',
    number: 2015
  }, {
    title: '员工保密竞业禁止协议（入职必签&防止恶性同业竞争）',
    url: '',
    number: 1044
  }, {
    title: '劳务合同（劳务关系&不交社保）',
    url: '',
    number: 6899
  }, {
    title: '一致行动人协议书（创业团队必签&防止团队分裂）',
    url: '',
    number: 2401
  }, {
    title: '试用期管理考核办法',
    url: '',
    number: 10663
  }, {
    title: '公司员工手册（入职必签，可根据企业情况调整内容）',
    url: '',
    number: 6298
  }, {
    title: '录用通知书（正规公司必备&规避争议）',
    url: '',
    number: 2341
  }]
}, {
  id: 6,
  title: '股权类合同',
  son: [{
    title: '股权代持协议（代持有风险，必须签署严格协议）',
    url: '',
    number: 968
  }, {
    title: '股权转让协议(不进行工商变更版)',
    url: '',
    number: 1054
  }, {
    title: '股权质押合同（质押有风险，大金额建议咨询律师）',
    url: '',
    number: 2458
  }, {
    title: '股权转让协议（工商变更版）',
    url: '',
    number: 3012
  }]
}, {
  id: 7,
  title: '投资类合同',
  son: [{
    title: '保密协议（约束投资机构&投资人，融资必用）',
    url: '',
    number: 2168
  }, {
    title: '债转股投资协议（如金额较大，建议咨询律师）',
    url: '',
    number: 10366
  }, {
    title: '增资协议（如金额较大，建议咨询律师）',
    url: '',
    number: 2635
  }]
}, {
  id: 8,
  title: '融资类',
  son: [{
    title: '保证合同（如金额较大，建议咨询律师）',
    url: '',
    number: 1055
  }, {
    title: '抵押担保合同（如金额较大，建议咨询律师）',
    url: '',
    number: 2016
  }]
}, {
  id: 9,
  title: '其它常用',
  son: [{
    title: '房屋租赁合同（有些房东提供的合同有漏洞，请比对）',
    url: '',
    number: 4551
  }, {
    title: '买卖合同（行业通用版）',
    url: '',
    number: 9339
  }, {
    title: '借款合同（借款有讲究，规避收不到钱的风险）',
    url: '',
    number: 1068
  }]
}]

// 热门城市
export const cityArr = ['北京市', '上海市', '广州市', '深圳市', '青岛市', '无锡市', '重庆市', '武汉市', '东莞市']
