
import Vue from 'vue/dist/vue.esm.js'

// 去空格
Vue.prototype.trimStr = function (str) {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

// 检查token
Vue.prototype.Check = function () {
  let timestamp = Date.parse(new Date())
  let token = localStorage.getItem('login')
  let tokenTime = localStorage.getItem('loginTime')

  if (token && tokenTime > timestamp) {
    return true
  } else {
    return false
  }
}

// 是否手机号码
/** 判断是否是手机号**/
Vue.prototype.isPhoneNumber = function (tel) {
  var reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/
  return reg.test(tel)
}

// 检查首页商品
Vue.prototype.CheckGoodData = function () {
  let timestamp = Date.parse(new Date())
  let txtData = localStorage.getItem('goodsArr')
  let txtDataTime = localStorage.getItem('goodsArrTime')
  if (txtData && txtDataTime > timestamp) {
    return true
  } else {
    return false
  }
}
