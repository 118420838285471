import axios from 'axios'
import vm from '../main'
// import router from '../router/index'
// import config from '../config'
// defaults
// axios.defaults.baseURL = 'http://localhost:3010/api/'
axios.defaults.baseURL = 'http://api.chmruige.cn/api/'
// axios.defaults.baseURL = 'http://ba.iphaowu.com/api/'
    // axios.defaults.method = 'get'
    // 请求数据格式转化
    // axios.defaults.withCredentials = true
    // 请求拦截器
axios.interceptors.request.use(
    conf => {
        let token = localStorage.getItem('login')
        conf.headers = {
            Authorization: 'Bearer ' + token,
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
        return conf
    },
    err => {
        return err
    }
)

// 响应拦截器
axios.interceptors.response.use(
    response => {
        let res = response.data
        if (res.code === 203) {
            vm.$message({
                message: '请登录后，再操作！',
                type: 'warning'
            })
            localStorage.removeItem('login')
            localStorage.removeItem('loginTime')
            localStorage.removeItem('userId')
            localStorage.removeItem('userName')
                // location.reload()
            vm.$router.push({ path: 'login' })
        }
        return res
    },
    err => {
        return err
    }
)

export default (method, url, data, option) => {
    return axios({
        method,
        url,
        data,
        option
    })
}