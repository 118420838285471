import {
  USER, NAV, CARTNUM
} from './mutation-types.js'

export default {
  // 用户登录状态
  userSync ({
    dispatch,
    commit
  }, value) {
    return commit(USER, value)
  },
  // 用户登录状态
  navSync ({
    dispatch,
    commit
  }, value) {
    return commit(NAV, value)
  },
  // 购物车数量
  cartSync ({
    dispatch,
    commit
  }, value) {
    return commit(CARTNUM, value)
  }
}
