import {
  USER, NAV, CARTNUM
} from './mutation-types.js'

export default {
  // 用户登录状态
  [USER] (state, value) {
    state.user = value
    state.loginStatus = true
  },
  // 导航状态
  [NAV] (state, value) {
    state.navNum = value
  },
  // 购物车数量
  [CARTNUM] (state, value) {
    state.cartNum = value
  }
}
